import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';

function Index() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);
  return (
    <main>
      <Helmet>
        <title>gay nigga testing</title>
      </Helmet>
      <h1>gay nigga testing</h1>
      <h2>
        Deployed with{' '}
        <a
          href="https://youtu.be/GWNwwuoc4Ro"
          target="_blank"
          rel="noreferrer noopener"
        >
          vercel
        </a>
        !
      </h2>
      <p>
        <a
          href="https://youtu.be/GWNwwuoc4Ro"
          target="_blank"
          rel="noreferrer noopener"
        >
          test
        </a>{' '}
        <a href="https://youtu.be/GWNwwuoc4Ro">test</a> i love farting, <code>/src</code> fart fart fart <code>/api</code>{' '}
        omfg omfg {' '}
        <a href="https://youtu.be/GWNwwuoc4Ro">node.js mazafaka omfg</a> function. See{' '}
        <a href="/api/date">
          <code>api/date</code> omfg typepspcisoripitp
        </a>
        .
      </p>
      <br />
      <h2>ass:</h2>
      <p>{date ? date : 'nigga fart...'}</p>
    </main>
  );
}

export default Index;
